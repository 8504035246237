<template>
  <lf-modal
    :title="isEditMode ? t('PRESETS.EDIT_PRESET') : t('PRESETS.ADD_PRESET')"
    :close="close"
    large
  >
    <template #content>
      <form @submit="submit" class="p-6">
        <div class="w-full h-full space-y-4">
          <lf-input
            :placeholder="$t('PRESETS.PRESET_NAME')"
            name="name"
            data-cy="user-modal-name"
          />
          <lf-textarea
            name="description"
            class="resize-none w-full h-30 border-gray-200 placeholder-gray-300 rounded"
            rows="3"
            noresize
            data-cy="user-modal-description"
            :placeholder="$t('COMMON.DESCRIPTION')"
            :character-limit="TEXT_CHARACTER_LIMIT_DEFAULT"
          />
          <user-modal-clients-list
            v-if="isLendflowUser"
            v-model="presetModalValues.assigned_client_ids"
            data-cy="user-modal-clients-list"
            :title="$t('COMMON.ASSIGNMENT')"
            has-border
          />
          <error-message
            name="assigned_client_ids"
            class="pl-2 text-xxs sm:text-xs text-red-700"
          />
          <assign-workflow-templates
            v-model="presetModalValues.assigned_workflow_template_ids"
            :assigned-client-ids="presetModalValues.assigned_client_ids"
            :preset-wfb-templates="presetData.assigned_workflow_templates"
            data-cy="assign-workflow-template"
          />
          <error-message
            name="assigned_workflow_template_ids"
            class="pl-2 text-xxs sm:text-xs text-red-700"
          />
        </div>
        <div class="flex justify-end">
          <modal-buttons
            class="bg-white"
            :on-cancel="close"
            :disabled="isSubmitting"
            save-is-submit
          />
        </div>
      </form>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import { reactive, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useForm, ErrorMessage } from "vee-validate";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";

import { TEXT_CHARACTER_LIMIT_DEFAULT } from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import { callStoreAction } from "@/helpers/vee-validate";
import usePermissionsPresetStore from "@/stores/permissionsPreset";

import UserModalClientsList from "@/views/profile/modals/UserModalClientsList.vue";
import AssignWorkflowTemplates from "@/components/presets/presetModal/AssignWorkflowTemplates.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";

import type { PermissionsPreset } from "@/models/permissionsPreset";

const props = defineProps({
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  presetData: {
    type: Object as PropType<PermissionsPreset>,
    default: () => ({
      name: "",
      description: "",
      assigned_client_ids: [],
      assigned_workflow_template_ids: [],
      assigned_clients: []
    })
  },
  isEditMode: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();
const { isLendflowUser } = useAuth();
const permissionsPresetStore = usePermissionsPresetStore();

const presetModalValues = reactive(
  cloneDeep({
    ...props.presetData,
    assigned_workflow_template_ids: compact(
      props.presetData.assigned_workflow_template_ids
    ),
    assigned_client_ids: props.presetData.assigned_clients.map(
      (client) => client.id
    )
  })
);

const { handleSubmit, isSubmitting } = useForm({
  initialValues: presetModalValues
});

const submit = handleSubmit(async (values, actions) => {
  const payload: Partial<PermissionsPreset> = {
    name: values.name,
    description: values.description,
    assigned_workflow_template_ids:
      presetModalValues.assigned_workflow_template_ids
  };

  if (isLendflowUser) {
    payload.assigned_client_ids = presetModalValues.assigned_client_ids;
  }

  if (props.isEditMode) {
    payload.id = presetModalValues.id;
  }

  try {
    await callStoreAction(
      payload,
      actions,
      props.isEditMode ? "updatePreset" : "createPreset",
      permissionsPresetStore
    );
  } catch (e) {
    const errors = e as { formattedErrors: Record<string, string> };
    Object.keys(errors.formattedErrors).forEach((key) => {
      if (key.includes("assigned_workflow_template_ids")) {
        actions.setFieldError(
          "assigned_workflow_template_ids",
          errors.formattedErrors[key]
        );
      }
      if (key.includes("assigned_client_ids")) {
        actions.setFieldError(
          "assigned_client_ids",
          errors.formattedErrors[key]
        );
      }
    });
    return;
  }
  props.close();
});
</script>
